import React, { useEffect, useState } from "react";
import adminLayout from "../../hoc/adminLayout";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import axios from "axios";

import Select from "react-select";
import { BaseUrl, token } from "../../utils/Token";
import Loader from "../../common/Loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// function isObjectIdLike(value) {
//   return typeof value === "string" && /^[0-9a-fA-F]{24}$/.test(value);
// }

function Product() {
  const [shortname, setShortName] = useState("");
  const [longName, setLongName] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const [brandApi, setBrandApi] = useState([]);
  const [tag, settag] = useState("")
  const [categoryApi, setCategoryApi] = useState([]);
  const [category, setCategory] = useState("");
  const [Brand, setBrand] = useState("");
  const [desc, setDesc] = useState("");
  const [product, setProduct] = useState("");
  const [productApi, setProductApi] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [selectedRecomend, setSelectRecomend] = useState([]);
  const [recomonded, setRecomonded] = useState([]);
  const navigate = useNavigate();
  const [vendordata, setvendordata] = useState([])
  const [vendor, setvendor] = useState("")


  const [file, setFile] = useState("");
  const [hsn, setHsn] = useState("");
  const [type, setType] = useState("");
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);

  const [rows, setRows] = useState([{ type: "", value: "" }]);

  // const addRow = (e) => {
  //   e.preventDefault();

  //   setRows([...rows, { type: "", value: "" }]);
  // };

  const handleChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/fetch_users_admin?type=Vendor`,
        {
          headers,
        }
      );
      setvendordata(response.data.data);
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };

  useEffect(() => {
    fetchData()
  }, [])

  const addRow = (e) => {
    e.preventDefault();
    setRows([...rows, { type: "", value: "" }]);
  };

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };
  const getCategory = () => {
    axios.get(`${BaseUrl}/category`, { headers }).then((res) => {
      setCategoryApi(res.data.data);
    });
    console.log(categoryApi);
    // console.log(category[3].parent_category.name);
  };

  const BrandgetApi = () => {
    axios.get(`${BaseUrl}/brands`, { headers }).then((res) => {
      setBrandApi(res.data.data);
    });
    console.log(brandApi);
    // console.log(category[3].parent_category.name);
  };

  const ProductgetApi = () => {
    axios.get(`${BaseUrl}/product`, { headers }).then((res) => {
      // setProductApi(res.data.data);
      setOptions(res.data.data);
    });
    console.log(productApi);
    // console.log(category[3].parent_category.name);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);

    // const ProductObject = Object.keys(relatedProducts);

    const formData = new FormData();
    formData.append("short_name", shortname);
    formData.append("long_name", longName);
    formData.append("hsn", hsn);
    formData.append("tag", tag)

    formData.append("price_range", priceRange);
    formData.append("brand", Brand);
    formData.append("category", category);
    formData.append("description", desc);
    // formData.append("related_products", relatedProducts);

    selectedOptions.forEach((option) => {
      formData.append("related_products[]", option.value);
    });

    selectedRecomend.forEach((option) => {
      formData.append("recommended_products[]", option.value);
    });

    rows.forEach((item, index) => {
      formData.append(`more_info[${index}][type]`, item.type);
      formData.append(`more_info[${index}][value]`, item.value);
    });



    formData.append("vendor", vendor)

    // Rest of your form submission logic using formData...

    formData.append("type", type);
    formData.append("value", value);
    console.log(relatedProducts);
    console.log(recomonded);
    console.log(type);
    console.log(value);

    formData.append("file", file); // Add the file to the form data

    axios
      .post(`${BaseUrl}/product`, formData, { headers })
      .then((response) => {
        // Handle successful response
        console.log(response.data);
        if (response.data.status == "OK") {
          toast.success("Data Submitted Successfully");
          setOpen(false);
          navigate("/viewproduct");
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  useEffect(() => {
    getCategory();
    BrandgetApi();
    ProductgetApi();
  }, []);



  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };



  const handleSelectionChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const handleRecomondedChange = (selectedRecomend) => {
    setSelectRecomend(selectedRecomend);
  };

  return (
    <>

      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  addProduct">
                <h4 className="px-3 py-3">Add Product</h4>
                <div className="">
                  <Form onSubmit={handleSubmit}>
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Short Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            style={{ textTransform: "capitalize" }}
                            required
                            value={shortname}
                            onChange={(e) => setShortName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Long Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            style={{ textTransform: "capitalize" }}
                            required
                            value={longName}
                            onChange={(e) => setLongName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Description
                          </label>
                          <input
                            type="text"
                            value={desc}
                            style={{ textTransform: "capitalize" }}
                            name="description"
                            onChange={(e) => setDesc(e.target.value)}
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Category
                          </label>
                          <select
                            className="form-select"
                            name="category"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option selected>Select a Category</option>
                            {categoryApi.map((item) => {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Vendor
                          </label>
                          <select
                            className="form-select"
                            name="category"
                            value={vendor}
                            onChange={(e) => setvendor(e.target.value)}
                          >
                            <option selected>Select a Vendor</option>
                            {vendordata.map((item) => {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.username}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Related Product{" "}
                            <span className="text-danger">*</span>
                          </label>

                          <Select
                            isMulti
                            required
                            name="related_products[]"
                            options={options.map((option) => ({
                              value: option._id, // Use option._id as the value
                              label: option.short_name,
                            }))}
                            value={selectedOptions} // Change to selectedOptions
                            onChange={handleSelectionChange}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Recommended Product
                          </label>

                          <Select
                            isMulti
                            name="recommended_products[]"
                            options={options.map((option) => ({
                              value: option._id, // Use option._id as the value
                              label: option.short_name,
                            }))}
                            value={selectedRecomend} // Change to selectedOptions
                            onChange={handleRecomondedChange}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Brand
                          </label>

                          <select
                            className="form-select"
                            name="brand"
                            aria-label="Default select example"
                            value={Brand}
                            onChange={(e) => setBrand(e.target.value)}
                          >
                            <option selected>Select a Brand</option>
                            {brandApi.map((item) => {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </Col>

                      {/*  */}

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Price Range
                          </label>
                          <input
                            type="text"
                            name="price_range"
                            required
                            className="form-control"
                            value={priceRange}
                            onChange={(e) => setPriceRange(e.target.value)}
                          />
                        </FormGroup>
                      </Col>



                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Image
                          </label>
                          <input
                            type="file"
                            name="file"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Hsn <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            required
                            value={hsn}
                            onChange={(e) => setHsn(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Tag <span className="text-danger">*  <small className="text-muted">Separate each tag with a comma (e.g., Tag1, Tag2, Tag3)</small></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            required
                            placeholder="Enter tags separated by commas"
                            value={tag}
                            onChange={(e) => settag(e.target.value)}
                          />

                        </FormGroup>
                      </Col>
                      {/* <Col md="12">
                        <div className="">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Key</th>
                                <th>Value</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <Form>
                              <tbody>
                                {rows.map((row, index) => (
                                  <tr key={index}>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Type"
                                        value={row.key}
                                        onChange={(e) =>
                                          handleChange(
                                            index,
                                            "key",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Value"
                                        value={row.value}
                                        onChange={(e) =>
                                          handleChange(
                                            index,
                                            "value",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {index === rows.length - 1 && (
                                        <button
                                          className="btn bg-primary text-white"
                                          onClick={addRow}
                                        >
                                          Add row
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Form>
                          </table>
                        </div>
                      </Col> */}
                      <Col md="12">
                        <div className="">
                          <div className="w-100 d-flex justify-content-between mb-3">
                            <h5 className="fw-bold">More info</h5>
                            <button
                              onClick={addRow}
                              className="btn bg-primary text-white"
                            >
                              Add Row
                            </button>
                          </div>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Type <span className="text-danger">*</span></th>
                                <th>Value <span className="text-danger">*</span></th>
                              </tr>
                            </thead>
                            <tbody>
                              {rows.map((row, index) => (
                                <tr key={index}>
                                  <td>
                                    <FormGroup>
                                      <input
                                        type="text"
                                        name={`more_info[${index}].type`}
                                        placeholder="Size , color , cloth type etc."
                                        required
                                        className="form-control"
                                        value={row.type}
                                        onChange={(e) =>
                                          handleChange(
                                            index,
                                            "type",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </td>
                                  <td>
                                    <FormGroup>
                                      <textarea
                                        type="text"
                                        name={`more_info[${index}].value`}
                                        className="form-control"
                                        placeholder="xl , white  , woolen  etc."
                                        value={row.value}
                                        required
                                        onChange={(e) =>
                                          handleChange(
                                            index,
                                            "value",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="">
                          <button className="btn">Add Product</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Loader open={open} />
      </section>
    </>
  );
}

export default adminLayout(Product);
