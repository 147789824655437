import React, { useEffect, useState } from 'react';
import adminLayout from '../../hoc/adminLayout';
import Loader from '../../common/Loader';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { BaseUrl } from '../../utils/Token';
import { Modal, Button } from 'react-bootstrap'; // Importing Modal and Button from react-bootstrap

function AddCountry() {
    const [open, setopen] = useState(false);
    const [data, setdata] = useState([]);
    const [active, setactive] = useState("")
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [openModal, setOpenModal] = useState(false); // State to manage modal visibility
    const [stdCode, setStdCode] = useState(''); // Store STD code for modal input
    const [countryCode, setCountryCode] = useState(''); // Store country code for API call

    const handlecountry = () => {
        axios.get(`${BaseUrl}/fetchAllCountries`).then((res) => {
            setdata(res.data.data);
        }).catch(error => console.error("Error fetching countries:", error));
    };

    useEffect(() => {
        handlecountry();
    }, []);

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        console.log("Selected country:", selectedOption);
    };

    const columns = [
        { field: 'name', headerName: 'Country Name', flex: 1 },
        { field: 'code', headerName: 'Country Code', flex: 1 },
        { field: 'std_code', headerName: 'STD Code', flex: 1 },

        {
            field: 'is_active', headerName: 'Active Status', flex: 1,
            renderCell: (params) => {
                return (
                    <label className="containercheckbox">
                        <input
                            type="checkbox"
                            checked={params.row.is_active}
                            onClick={(e) => handleCheckboxClick(e, params.row.code, params.row.std_code, params.row.is_active)}
                        />
                        <div className="checkmark"></div>
                    </label>
                );
            }
        }
    ];

    const handleCheckboxClick = (e, code, std, active) => {
        setStdCode(std); // Set the STD code for the modal input
        setCountryCode(code); // Set the country code to use for the API update
        if (active) {
            setOpenModal(false); // Open the modal when checkbox is clicked
            setactive(active)
            handleSave(code, active)

        } else {
            setOpenModal(true); // Open the modal when checkbox is clicked
            setactive(active)
        }
    };

    const handleSave = (councode, funcactive) => {

        setopen(true);


        // Perform the API call to update country status
        axios.put(`${BaseUrl}/updateCountries`, { code: councode ?? countryCode, is_active: !funcactive ?? !active })
            .then((res) => {
                console.log("Country status updated:", res.data);
                handlecountry(); // Refresh data after update
                setopen(false);
                setOpenModal(false); // Close the modal after API update
            })
            .catch(error => {
                console.error("Error updating country status:", error);
                setopen(false);
            });
    };

    const handleotp = async (e) => {
        e.preventDefault()
        axios.put(`${BaseUrl}/updateStdCodes`, { code: countryCode, std_code: stdCode })
            .then((res) => {




                setopen(false);
                setOpenModal(false); // Close the modal after API update
                handleSave()
            })
            .catch(error => {
                console.error("Error updating country status:", error);
                setopen(false);
            });
    }

    const getRowId = (row) => row._id;

    return (
        <>
            <Loader open={open} />
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <h3>Country</h3>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="mt-5" style={{ width: "100%", zIndex: "888" }}>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    getRowId={getRowId}
                                    pagination={false}
                                    disableSelectionOnClick
                                    sx={{
                                        '& .MuiDataGrid-columnHeader': { fontWeight: 'bold' },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* React Bootstrap Modal */}
            <Modal show={openModal} onHide={() => setOpenModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark" style={{ fontSize: "18px" }}>Enter STD Code for Country</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="" onSubmit={(e) => handleotp(e)}>
                        <input
                            type="text"
                            value={stdCode}
                            onChange={(e) => setStdCode(e.target.value)}
                            placeholder="Enter STD Code"
                            className="form-control"
                        />
                        <button className='btn bg-primary text-white mt-3'>Submit</button>
                    </form>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default adminLayout(AddCountry);
