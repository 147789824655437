import React, { useEffect, useState } from "react";
import adminLayout from "../../hoc/adminLayout";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import axios from "axios";
import { BaseUrl, token } from "../../utils/Token";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";

function AddSlider() {
  const [flow, setFlow] = useState("");
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);

    const formData = new FormData();

    formData.append("file", file); // Add the file to the form data

    axios
      .post(`${BaseUrl}/slider`, formData, { headers })
      .then((response) => {
        // Handle successful response
        console.log(response.data);

        if (response.data.status == "OK") {
          toast.success("Data Submitted Successfully");
          setOpen(false);
          navigate("/sliderlist");
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        toast.error("Data Not Submitted ");
        setOpen(false);
      });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  return (
    <>
      <Loader open={open} />
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white box">
                <h4 className="px-3 py-3"> Add Slider</h4>
                <div className="">
                  <Form onSubmit={handleSubmit}>
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Image <span className="text-danger">*</span>
                          </label>
                          <input
                            type="file"
                            name="file"
                            required
                            className="form-control"
                            onChange={handleFileChange}
                          />
                          <span className="text-danger" style={{ fontSize: "12px" }}>Upload a file in 2:1 (example height 500px width 1000px)</span>
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <div className="">
                          <button className="btn bg-primary text-white">
                            Add Slider
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(AddSlider);
