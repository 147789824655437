import React, { useEffect, useState } from "react";
import adminLayout from "../../hoc/adminLayout";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { BaseUrl, token } from "../../utils/Token";
import axios from "axios";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";

function AddSection() {
  const [fileName, setFileName] = useState("");
  const [selectcategory, setSelectCategory] = useState("");
  const [data, setData] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [flowApi, setFlowApi] = useState([]);
  const [flow, setFlow] = useState("");
  const [name, setName] = useState("");
  const [brandshow, setBrandshow] = useState(false);
  const [categshow, setCategshow] = useState(false);
  const [productshow, setProductShow] = useState(false);
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [updateid, setUpdateid] = useState("");

  const navigate = useNavigate();

  const location = useLocation();

  const handleSelectionChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const edit = location.pathname === `/editsection/${id}`;
  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFileName(uploadedFile.name);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/section`, {
        headers,
      });
      console.log(id);
      console.log(response.data.data[id]);
      let sectionData = response.data.data[id];
      setName(response.data.data[id].name);
      setUpdateid(response.data.data[id]._id);

      setFlow(response.data.data[id].flow._id);
      if (sectionData.brand_to_show.length > 0) {
        setSelectCategory("brands");
        const selectedCategoryOptions = sectionData.brand_to_show.map(
          (category) => ({
            value: category._id,
            label: category.name,
          })
        );
        setSelectedOptions(selectedCategoryOptions);
      } else if (sectionData.category_to_show.length > 0) {
        setSelectCategory("category");

        // Map the category_to_show data to an array of selected options
        const selectedCategoryOptions = sectionData.category_to_show.map(
          (category) => ({
            value: category._id,
            label: category.name,
          })
        );

        setSelectedOptions(selectedCategoryOptions);
      } else if (sectionData.product_to_show.length > 0) {
        setSelectCategory("product");
        const selectedCategoryOptions = sectionData.product_to_show.map(
          (category) => ({
            value: category._id,
            label: category.name,
          })
        );

        setSelectedOptions(selectedCategoryOptions);
      }
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(`https://twinscart.com:8000/section`, {
  //       headers,
  //     });

  //     const sectionData = response.data.data[0]; // Assuming the data structure is as expected

  //     // Update state variables with the fetched data
  //     setName(sectionData.name);
  //     setFlow(sectionData.flow._id);

  //     if (sectionData.brand_to_show.length > 0) {
  //       setSelectCategory("brands");
  //     } else if (sectionData.category_to_show.length > 0) {
  //       setSelectCategory("category");
  //       const selectedCategoryOptions = sectionData.category_to_show.map(
  //         (option) => ({
  //           value: option.value,
  //           label: option.label, // Adjust the property name as per your data structure
  //         })
  //       );
  //       setSelectedOptions(selectedCategoryOptions);
  //     } else {
  //       setSelectCategory("product");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching section data:", error);
  //   }
  // };

  const FlowgetApi = () => {
    axios
      .get(`${BaseUrl}/flows`, { headers })
      .then((res) => {
        console.log("Flow API Response:", res.data.data); // Log the response data for debugging
        setFlowApi(res.data.data);
      })
      .catch((error) => {
        console.error("Flow API Error:", error); // Log any API errors for debugging
      });
  };

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const categorytoShowApi = () => {
    if (selectcategory) {
      axios.get(`${BaseUrl}/${selectcategory}`, { headers }).then((res) => {
        setData(res.data.data);
        console.log(res.data.data);
        console.log(selectcategory);
      });
    } else {

    }
  };

  const resetForm = () => {
    setName("");
    setFlow("");
    setSelectCategory("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);

    // const ProductObject = Object.keys(relatedProducts);

    const formData = new FormData();

    // formData.append("related_products", relatedProducts);
    if (id) {
      formData.append("_id", updateid);
    }
    formData.append("name", name);

    {
      categshow &&
        selectedOptions.forEach((option) => {
          formData.append("category_to_show[]", option.value);
        });
    }
    {
      brandshow &&
        selectedOptions.forEach((option) => {
          formData.append("brand_to_show[]", option.value);
        });
    }

    {
      productshow &&
        selectedOptions.forEach((option) => {
          formData.append("product_to_show[]", option.value);
        });
    }

    formData.append("flow", flow);

    console.log("Current flow:", flow);

    console.log(brandshow);

    if (id) {
      axios
        .put(`${BaseUrl}/section`, formData, { headers })
        .then((response) => {
          // Handle successful response
          console.log(response.data);
          if (response.data.status == "OK") {
            toast.success("data Submitted Succesffully");

            setOpen(false);
            // navigate("/sectionlist");
          }
        })
        .catch((error) => {
          // Handle error
          console.error(error);
          toast.error("data Not Submitted ");

          setOpen(false);
        });
    } else {
      axios
        .post(`${BaseUrl}/section`, formData, { headers })
        .then((response) => {
          // Handle successful response
          console.log(response.data);
          if (response.data.status == "OK") {
            toast.success("data Submitted Succesffully");

            setOpen(false);
            navigate("/sectionlist");
          }
        })
        .catch((error) => {
          // Handle error
          console.error(error);
          toast.error("data Not Submitted ");
          setOpen(false);
        });
    }
  };

  const checkseleccategory = () => {
    if (selectcategory === "brands") {
      setBrandshow(true);
      setCategshow(false);
      setProductShow(false);
    } else if (selectcategory === "category") {
      setCategshow(true);
      setProductShow(false);
      setBrandshow(false);
    } else if (selectcategory === "product") {
      setProductShow(true);
      setBrandshow(false);
      setCategshow(false);
    }
  };

  useEffect(() => {
    categorytoShowApi();
    FlowgetApi();
    checkseleccategory();
    if (id) {
      fetchData();
    }
  }, [selectcategory]);

  return (
    <>
      <Loader open={open} />
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white box">
                <h4 className="px-3 py-3">
                  {id ? "Update Section" : "Add Section"}
                </h4>
                <div className="">
                  <Form onSubmit={handleSubmit}>
                    <Row className="p-3 productform">
                      <Col md="4" className="mb-3">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="4" className="mb-3">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Select Category
                          </label>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            value={selectcategory}
                            onChange={(e) => setSelectCategory(e.target.value)}
                          >
                            <option selected>Open this select Category</option>
                            <option value="brands">Brand</option>
                            <option value="category">Category</option>
                            <option value="product">Product</option>
                          </select>
                        </FormGroup>
                      </Col>

                      {categshow && (
                        <>
                          <Col md="4" className="mb-3">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                Category to show
                              </label>

                              <Select
                                isMulti
                                name="category_to_show[]"
                                options={data.map((option) => ({
                                  value: option._id,
                                  label: option.name,
                                }))}
                                value={selectedOptions}
                                onChange={handleSelectionChange}
                              />
                            </FormGroup>
                          </Col>
                        </>
                      )}

                      {brandshow && (
                        <>
                          <Col md="4" className="mb-3">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                Brand to show
                              </label>

                              <Select
                                isMulti
                                name="brand_to_show[]"
                                options={data.map((option) => ({
                                  value: option._id, // Use option._id as the value
                                  label: option.name,
                                }))}
                                value={selectedOptions} // Change to selectedOptions
                                onChange={handleSelectionChange}
                              />
                            </FormGroup>
                          </Col>
                        </>
                      )}

                      {productshow && (
                        <>
                          <Col md="4" className="mb-3">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                Product to show
                              </label>

                              <Select
                                isMulti
                                name="product_to_show[]"
                                options={data.map((option) => ({
                                  value: option._id, // Use option._id as the value
                                  label: option.short_name,
                                }))}
                                value={selectedOptions} // Change to selectedOptions
                                onChange={handleSelectionChange}
                              />
                            </FormGroup>
                          </Col>
                        </>
                      )}

                      <Col md="4" className="mb-3">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Flow
                          </label>

                          <select
                            className="form-select"
                            name="flow"
                            aria-label="Default select example"
                            value={flow}
                            onChange={(e) => setFlow(e.target.value)}
                          >
                            <option>Select a Flow</option>
                            {flowApi.map((item) => {
                              return (
                                <option key={item._id} value={item._id}>
                                  {/* {console.log(item._id)} */}
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <div className="">
                          <button class="btn bg-primary text-white">
                            {id ? "Update" : "Add"} Section
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(AddSection);
