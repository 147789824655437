import React, { useEffect, useState } from 'react';
import adminLayout from '../../hoc/adminLayout';
import Loader from '../../common/Loader';
import Select from "react-select";
import axios from 'axios';
import { BaseUrl } from '../../utils/Token';
import { DataGrid } from '@mui/x-data-grid';

function AddNotification() {
    const [open, setopen] = useState(false);
    const [data, setdata] = useState([]);
    const [notificationdata, setnotificationdata] = useState([]);

    const [selectuser, setselectuser] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [notificationFields, setNotificationFields] = useState({
        title: '',
        heading: '',
        body: '',
        image: null
    });

    let token = localStorage.getItem("token");

    const headers = {
        Authorization: `Bearer ${token}`
    };

    const handleusertype = () => {
        axios.get(`${BaseUrl}/fetch_users_admin?type=${selectuser}`, { headers })
            .then((res) => {
                setdata(res.data.data);
            })
            .catch(error => console.error("Error fetching users:", error));
    };

    const handlenotification = () => {

        axios.get(`${BaseUrl}/notifications`, { headers })
            .then((res) => {
                setnotificationdata(res.data.data);
            })
            .catch(error => console.error("Error fetching users:", error));
    };

    useEffect(() => {
        if (selectuser !== "alluser") {
            handleusertype();
        }
    }, [selectuser]);

    useEffect(() => {
        handlenotification()
    }, [])


    const columns = [
        { field: 'title', headerName: 'Title', flex: 1 },
        { field: 'heading', headerName: 'Heading', flex: 1 },

    ];

    const handleNotificationChange = (e) => {
        const { name, value } = e.target;
        setNotificationFields((prevFields) => ({
            ...prevFields,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        setNotificationFields((prevFields) => ({
            ...prevFields,
            image: e.target.files[0]
        }));
    };

    const handleUserSelection = (selectedOption) => {
        // Ensure you correctly handle the multi-select values
        // selectedOption will be an array when isMulti is true
        setSelectedUsers(selectedOption ? selectedOption.map(option => option.value) : []);
    };

    const sendNotification = () => {
        const formData = new FormData();
        formData.append("title", notificationFields.title);
        formData.append("heading", notificationFields.heading);
        formData.append("body", notificationFields.body);
        formData.append("file", notificationFields.image);

        // Handle if all users or specific users are selected
        if (selectuser === "alluser") {
            formData.append("if_all", true);
        } else {
            selectedUsers.forEach((userId, index) => {
                formData.append(`user[${index}]`, userId); // Append user[0], user[1], etc.
            });
        }

        axios.post(`${BaseUrl}/notifications`, formData, { headers })
            .then((res) => {
                console.log("Notification sent:", res.data);
                handlenotification()
                setNotificationFields({ title: '', heading: '', body: '', image: null });
                setselectuser("");
                setSelectedUsers([]);
            })
            .catch(error => console.error("Error sending notification:", error));
    };


    const getRowId = (row) => row._id

    return (
        <>
            <Loader open={open} />
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3>Notification</h3>
                        </div>

                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <label>Send Notification To</label>
                                    <select value={selectuser} className='form-control form-select' onChange={(e) => setselectuser(e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="alluser">All Users</option>
                                        <option value="Vendor">Vendor</option>
                                        <option value="User">Users</option>
                                    </select>
                                </div>

                                {selectuser !== "alluser" && selectuser !== "" && (
                                    <div className="col-md-4">
                                        <label>Users</label>
                                        <Select
                                            isMulti
                                            options={data?.map(user => ({ label: `${user.username}`, value: user._id }))}
                                            onChange={handleUserSelection}
                                            value={data?.filter(user => selectedUsers.includes(user._id)).map(user => ({ label: user.username, value: user._id }))}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-md-4 mt-4">
                            <label>Title</label>
                            <input
                                type="text"
                                name="title"
                                value={notificationFields.title}
                                onChange={handleNotificationChange}
                                placeholder="Enter title"
                                className="form-control"
                            />
                        </div>

                        <div className="col-md-4 mt-3">
                            <label>Heading</label>
                            <input
                                type="text"
                                name="heading"
                                value={notificationFields.heading}
                                onChange={handleNotificationChange}
                                placeholder="Enter heading"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-4 mt-3">
                            <label>Image</label>
                            <input
                                type="file"
                                name="image"
                                onChange={handleImageChange}
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label>Body</label>
                            <textarea
                                name="body"
                                value={notificationFields.body}
                                onChange={handleNotificationChange}
                                placeholder="Enter notification body"
                                className="form-control"
                            />
                        </div>



                        <div className="col-md-12 mt-4">
                            <button onClick={sendNotification} className="btn bg-primary text-white">
                                Send Notification
                            </button>
                        </div>



                        <div className="col-md-12">
                            <div className="mt-5" style={{ width: "100%", zIndex: "888" }}>
                                <DataGrid
                                    rows={notificationdata}
                                    columns={columns}
                                    getRowId={getRowId}
                                    pagination={false}
                                    // Optional: Hides column filter menu for a cleaner view
                                    disableSelectionOnClick // Optional: Prevents row selection on cell click
                                    sx={{
                                        '& .MuiDataGrid-columnHeader': { fontWeight: 'bold' }, // Optional: Style headers for readability
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default adminLayout(AddNotification);
