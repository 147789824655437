import React, { useEffect, useState } from 'react'
import Loader from '../../common/Loader'
import { DataGrid } from '@mui/x-data-grid'
import { Col, Container, Row } from 'react-bootstrap'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import adminLayout from '../../hoc/adminLayout'
import { BaseUrl } from '../../utils/Token'
import { toast } from 'react-toastify'
import { IconButton } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";

function Settingpage() {
    const [open, setopen] = useState(false)
    const [editid, seteditid] = useState(false)

    const [data, setdata] = useState([])
    const token = localStorage.getItem("token")
    const [key, setkey] = useState("")
    const [value, setvalue] = useState("")



    const navigate = useNavigate();

    const headers = {
        Authorization: `Bearer ${token}`,
    };


    const getRowId = (row) => row._id

    const fetchData = async () => {
        setopen(true)
        try {
            const response = await axios.get(`${BaseUrl}/settings`, {
                headers,
            });
            if (response.data.status == "OK") {
                setdata(response.data.data);
                setopen(false)
            } else {
                setopen(false)
            }
        } catch (error) {
            console.error("Error fetching category data:", error);
            setopen(false)
        }
    };



    const columns = [

        { field: "s_key", headerName: "Title", flex: 1 },
        { field: "s_value", headerName: "Value", flex: 1 },
        {
            field: "edit",
            headerName: "Edit",
            width: 100,
            renderCell: (params) => {
                const id = params.row;
                return (
                    <Link >
                        <IconButton
                            style={{ color: "black" }}
                            aria-label="edit"
                            onClick={() => handleEditClick(id)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Link>
                );
            },
        },




    ];





    const handleEditClick = (item) => {
        setkey(item.s_key)
        seteditid(item._id)
        setvalue(item.s_value)
    }



    useEffect(() => {
        fetchData();
    }, []);

    const handlesubmit = (e) => {
        e.preventDefault()
        setopen(true)
        let requestData = {
            s_key: key,
            s_value: value
        }

        if (editid) {
            requestData._id = editid
            axios.put(`${BaseUrl}/settings`, requestData, { headers }).then((res) => {
                console.log(res)
                if (res.data.status == "OK") {
                    setopen(false)
                    toast.success("Data Submit Successfully")
                    setkey("")
                    setvalue("")
                    seteditid("")
                    fetchData()
                } else {
                    setopen(false)
                }
            })
        } else {
            axios.post(`${BaseUrl}/settings`, requestData, { headers }).then((res) => {
                console.log(res)
                if (res.data.status == "OK") {
                    setopen(false)
                    toast.success("Data Submit Successfully")
                    setkey("")
                    setvalue("")
                    seteditid("")
                    fetchData()
                } else {
                    setopen(false)
                }
            })
        }
    }
    return (
        <>
            <Loader open={open} />
            <section>
                <Container>
                    <Row>
                        <div className="col-md-12">
                            <h4 className="px-3 py-3 text-center">Add Setting </h4>
                        </div>
                        <form action="" onSubmit={(e) => handlesubmit(e)} className='mb-3'>
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <label htmlFor="">Title</label>
                                    <input type="text" className='form-control' value={key} onChange={(e) => setkey(e.target.value)} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="">Value</label>
                                    <input type="text" className='form-control' value={value} onChange={(e) => setvalue(e.target.value)} />
                                </div>
                                <div className="col-md-4">
                                    <button className='btn text-white bg-primary mt-3 '>{editid ? "Update" : "Submit"} </button>
                                </div>
                            </div>
                        </form>
                        <Col md="12">
                            <div className="bg-white  box">
                                <h4 className="px-3 py-3">Setting List</h4>
                                <div className="">
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        getRowId={getRowId}
                                        initialState={{
                                            ...data.initialState,
                                            pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        pageSizeOptions={[5, 10, 25]}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default adminLayout(Settingpage)
